

































































































































































































































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { Form } from 'element-ui'

@Component({ name: 'DepartmentList' })
export default class DepartmentList extends VueBase {
  private addDialogOpen = false
  private page = 1
  private allTotal = 0
  private isAdd = true
  private options = []
  private blackListContent = `
HTTP请求信息如下：

GET /index.html?action=login

HTTP/1.1

Host:www.demo.cn

Referer:https://www.demo.cn

User-Agent:Mozilla/5.0 (Windows NT 6.1; Win64; x64)

Content-Type:application/x-www-form-urlencoded

对应字段：

URL:https://www.demo.cn//index.html?action=login

Header key: Content-Type

`
  private blacklist = [
    {
      target: 1,
      operator: 1,
      value: 'Value',
      state: true,
    },
  ]
  private policyList = []
  private keyword = ''
  private form = {
    name: '',
    region: '',
    value: true,
  }

  private changeCheckBox(e: any, obj: any, key: any) {
    if (e) {
      obj[key] = 1
      return
    }
    obj[key] = 0
  }
  private addfiled() {
    if (this.configForm.blacklist_is_followglobal) {
      return
    }
    this.blacklist.push({
      target: 1,
      operator: 1,
      value: 'Value',
      state: true,
    })
  }
  private delfiled(index: any) {
    this.blacklist = this.blacklist.filter((item, key) => {
      return index !== key
    })
  }
  private newSelectData() {
    this.page = 1
    this.getTableData()
  }
  private configForm: any = {
    template_name: '',
    scanPolicy: '',
    proactiveVerification: '',
    saveResponseRight: false,
    Maximumlength: '',
    enable_version_header: true,
    version_header_name: 'MicroView',
    uid: 0,
    data_gather_is_followglobal: 0,
    blacklist_is_followglobal: 0,
  }
  private rules = {
    template_name: [
      {
        required: true,
        message: this.$t('menu.Form.namePlaceholder'),
        trigger: 'blur',
      },
    ],
    scanPolicy: [
      {
        required: true,
        message: this.$t('menu.Form.namePlaceholder'),
        trigger: 'blur',
      },
    ],

    proactiveVerification: [
      {
        required: true,
        message: this.$t('menu.Form.proactiveVerificationPlaceholder'),
        trigger: 'change',
      },
    ],
    version_header_name: [
      {
        validator: (rule: any, value: any, callback: any) => {
          const msg = this.$t('menu.Form.AgentHeaderPlaceholder')
          if (!value) {
            callback(new Error(msg as any))
          } else {
            callback()
          }
        },
        trigger: 'blur',
      },
    ],
    delivery: [],
    Maximumlength: [
      {
        // required: true,
        // message: this.$t('menu.Form.MaximumlengthPlaceholder'),
        validator: (rule: any, value: any, callback: any) => {
          const msg = this.$t('menu.Form.MaximumlengthPlaceholder')
          if (!value) {
            callback(new Error(msg as any))
          } else {
            callback()
          }
        },
        trigger: 'blur',
      },
    ],
    Maximumlength1: [
      {
        // required: true,
        // message: this.$t('menu.Form.MaximumlengthPlaceholder'),
        validator: (rule: any, value: any, callback: any) => {
          callback()
        },
        trigger: 'blur',
      },
    ],
    agent: [
      {
        validator: (rule: any, value: any, callback: any) => {
          const msg = this.$t('menu.Form.AgentPlaceholder')
          if (this.configForm.agent === '') {
            if (this.configForm.proactiveVerification === 0) {
              callback(new Error(msg as any))
            }
            callback()
          } else {
            callback(new Error(msg as any))
          }
        },
        trigger: 'blur',
      },
    ],
  }

  private async editTemplate(row: any) {
    this.isAdd = false
    // const res = await this.services.setting.getProjecttemplat({ id: row.id })
    // console.log(res)
    this.configForm = {
      template_name: row.template_name,
      scanPolicy: row.scan_id,
      proactiveVerification: row.vul_validation,
      uid: row.id,
      data_gather_is_followglobal: Number(row.data_gather_is_followglobal),
      blacklist_is_followglobal: row.blacklist_is_followglobal,
      saveResponseRight: row.data_gather.gather_res_body || false,
      Maximumlength: row.data_gather.method_pool_max_length || '',
      enable_version_header: row.data_gather.enable_version_header || true,
      version_header_name: row.data_gather.version_header_name || 'MicroView',
    }
    this.blacklist = row.blacklist
    this.addDialogOpen = true
  }

  private async delTemplate(id: number) {
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: 'delete-btn',
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除当前模板?
        </div>
        <div class="tip">
          删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      this.loadingStart()
      const { status, msg } = await this.services.setting.delProjecttemplat({
        id,
      })
      this.loadingDone()
      if (status !== 201) {
        this.$message.error('模板删除失败，请重试')
        return
      }
      this.$message.success('模板删除成功')
      await this.getTableData()
    })
  }

  private handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }
  private tableData = []
  async getTableData() {
    this.loadingStart()
    const res = await this.services.setting.listProjecttemplat({
      page: this.page,
      page_size: 10,
    })
    this.loadingDone()
    if (res.status === 201) {
      this.tableData = res.data
      this.allTotal = res.page.alltotal
      return
    }
    this.$message.error(res.msg)
  }

  private addDialogShow() {
    this.configForm = {
      template_name: '',
      scanPolicy: '',
      proactiveVerification: '',
      saveResponseRight: false,
      Maximumlength: '',
      uid: 0,
      data_gather_is_followglobal: 0,
      blacklist_is_followglobal: 0,
      enable_version_header: true,
      version_header_name: 'MicroView',
    }
    this.blacklist = []
    this.addDialogOpen = true
    this.isAdd = true
  }

  private cancelAdd() {
    this.addDialogOpen = false
    if (this.isAdd === false) {
      this.isAdd = true
    }
  }
  private creatProjecttemplat() {
    console.log(this.configForm.department)
    ;(this.$refs.ruleForm as Form).validate(async (valid: any) => {
      if (valid) {
        const params: any = {
          template_name: this.configForm.template_name,
          scan_id: this.configForm.scanPolicy,
          vul_validation: this.configForm.proactiveVerification,
          data_gather_is_followglobal:
            this.configForm.data_gather_is_followglobal,
          blacklist_is_followglobal: this.configForm.blacklist_is_followglobal,
          blacklist: this.blacklist || [],
        }
        if (this.configForm.uid) {
          params.id = this.configForm.uid
        }
        if (!this.configForm.data_gather_is_followglobal) {
          params.data_gather = {
            method_pool_max_length: this.configForm.Maximumlength,
            gather_res_body: this.configForm.saveResponseRight,
            enable_version_header: this.configForm.enable_version_header,
            version_header_name: this.configForm.version_header_name,
          }
          // params.method_pool_max_length = Number(this.configForm.Maximumlength)
          // params.gather_res_body = this.configForm.saveResponseRight
        }
        // if (!this.configForm.blacklist_is_followglobal) {
        //   params.blacklist = this.blacklist || []
        // }

        this.loadingStart()
        if (this.isAdd) {
          const { status, msg } =
            await this.services.setting.creatProjecttemplat(params)
          this.loadingDone()
          if (status !== 201) {
            this.$message.error('模板创建失败，请重试')
            return
          }
          this.$message.success('模板创建成功')
        } else {
          const { status, msg } = await this.services.setting.putProjecttemplat(
            params
          )
          this.loadingDone()
          if (status !== 201) {
            this.$message.error('模板编辑失败，请重试')
            return
          }
          this.$message.success('模板编辑成功')
        }
        this.addDialogOpen = false
        this.getTableData()
        this.isAdd = true
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
  fmtOptions(options: Array<any>) {
    options.forEach((item: any) => {
      if (item.id < 0) {
        item.disabled = true
      }
      item.value = { name: item.label, id: item.id }
      if (item.children.length) {
        this.fmtOptions(item.children)
      } else {
        delete item.children
      }
    })
  }
  private async getPolicy() {
    const { status, msg, data } = await this.services.setting.strategyUserList()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.policyList = data
  }
  created() {
    this.getPolicy()
    this.getTableData()
  }
}
